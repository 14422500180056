<template>
  <div class="container">
      <div class="row1">
          <img src="../assets/homeBg.c3c842f.png">
          <div>
              <p>快速稳定、简单易用、触达全球的短信服务</p>
              <p>E快讯(<span style="font-size:20px;">www.didisms.shop</span>)为您服务</p>
              <br>
              <p class="notice">公告：{{data.notice || '无'}}</p>
          </div>
      </div>
      <div class="row2">
          <div class="card1">
              <div class="t1">统计数据</div>
              <div class="c1">
                  <div class="card-w">
                    <div style="background:#2D8CF0;" class="card-left-icon">
                        <Icon type="md-cloud-upload" color="#fff" size="36"/>
                    </div>
                    <Card class="top-card">
                        <div class="number" style="color:#2D8CF0;">
                            {{data.countTotal || 0}}
                        </div>
                        <p>提交数量</p>
                    </Card>
                  </div>
                  <div class="card-w">
                    <div style="background:#64d572;" class="card-left-icon">
                        <Icon type="md-checkmark-circle" color="#fff" size="36"/>
                    </div>
                    <Card class="top-card">
                        <div class="number" style="color:#64d572;">
                            {{data.countOk || 0}}
                        </div>
                        <p>发送成功</p>
                    </Card>
                  </div>
                  <div class="card-w">
                    <div style="background:#f25e43;" class="card-left-icon">
                        <Icon type="md-close-circle" color="#fff" size="36"/>
                    </div>
                    <Card class="top-card">
                        <div class="number" style="color:#f25e43;">
                            {{data.countNo || 0}}
                        </div>
                        <p>发送失败</p>
                    </Card>
                  </div>
              </div>
          </div>
          <div class="card1" style="margin-left:20px;">
              <div class="t1">API接入功能</div>
              <div class="c2">
                  <span>支持国际短信、国内短信、语音验证码的接入</span>
              </div>
          </div>
      </div>
      <div class="row3">
          <div class="card1" style="height:auto;">
              <div class="t1">计费标准</div>
              <div class="c3">
                  <div class="bz">
                      国内短信：70字符/条，长短信（＞70字符时）67字符/条，不分中英文，含签名字数；国际短信：中文=国内计费，英文：140字符/条，长短信（＞140字符时）134字符/条。 短信中出现非ASCII编码字符，作中文标准计费。
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
      return {
          data: {
              countTotal: 0,
              countOk: 0,
              countNo: 0,
              notice: ''
          }
      }
  },
  mounted() {
    this.token = this.$store.state.token;
    this._fetch();
  },
  methods: {
      _fetch() {
          this.apiV1({
            method: 'get',
            url: '/index',
            headers: {'Authorization': this.token}
        }).then(response => {
            const { success, data } = response.data;
            if (success) {
                this.data = data;
            }
        });
      }
  }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    .row1 {
        display: flex;
        justify-content: center;
        align-items: center;
        >img {
            width: 30%;
        }
        >div {
            text-align: center;
            width: 580px;
            margin: 30px 0 30px 50px;
            >p {
                font-size: 30px;
                color: #3f8fcc;
                line-height: 1.5;
                font-weight: bold;
            }
            >p.notice {
                color: red;
                font-size: 17px;
            }
        }
    }
    .row2 {
        display: flex;
        margin: 10px 0 20px;
        .card-w {
            display: flex;
            .card-left-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width:64px;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            .top-card {
                color: #666;
                .number {
                    font-size: 20px;
                    font-weight: bold;
                    color: #515a6e;
                    text-align: center;
                }
            }
        }
    }
    .card1 {
        flex: 1;
        border-radius: 4px;
        height: 215px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        border: 1px solid #ebeef5;
    }
    .t1 {
        background-color: #3f8fcc;
        padding: 18px 20px;
        color: #fff;
    }
    .c1,.c2,.c3 {
        flex: 1;
        padding: 20px;
    }
    .c1 {
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
    }
    .c2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .c3 > .bz {
        padding: 8px 24px;
        background-color: #fdf6ec;
        color: #e6a23c;
    }
}
</style>
